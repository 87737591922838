import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { RegistrationForm } from './RegistrationForm';
import { PendingApproval } from './PendingApproval';
import { MyResources } from './MyResources';
import { ManageUsers } from './ManageUsers';
import { ManageResources } from './ManageResources';
import { Account } from './Account';
import { MySavedResources } from './MySavedResources';
import { useTranslation } from 'react-i18next';
import { DevButtons } from './DevButtons';

import { Box, Tab, Tabs } from '@mui/material';
import { Footer, Header } from '../../components';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

const Dashboard = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  const getTabValue = () => {
    // Returns number after # mark, or 0 if none present (when the dashboard first loads)

    if (window.location.hash.substring(1) === '') return 0;
    return parseInt(window.location.hash.substring(1), 10);
  };

  const [tab, setTab] = React.useState(getTabValue()); // Sets the initial tab based on the href

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const sendToHome = () => {
    // console.log('this should be working...');
    window.location.href = '/';
  };
  const tabStyle = {
    fontFamily: 'Nunito',
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 600,
  };

  // TODO: We have GOT to make this code more readable.
  return (
    <fragment>
      <Header dashboard={true} />
      <ThemeProvider theme={defaultTheme} /* Fix indents later*/>
        <Container component="main" maxWidth="lg" style={{ padding: '2rem 0' }}>
          <div className="signup__heading">
            <br></br>
            <p
              className="p1"
              style={{
                fontSize: '40px',
                fontWeight: '700',
                color: '#00233D',
                marginBottom: '-0.5rem',
              }}
            >
              {t('Dashboard')}
            </p>
          </div>
        </Container>
        {
          // Fix: separate behavior for admin user and regular approved user
          sessionStorage.getItem('isVerified') === 'false' ? (
            sendToHome()
          ) : user?.roles?.includes('100999') ? (
            <Container
              component="main"
              maxWidth="lg"
              style={{ padding: '2rem 0' }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    href="#0"
                    User
                    label={`${t('My')} ${t('Resource.Plural')}`}
                    {...a11yProps(0)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#1"
                    label={`${t('Manage Resources')}`}
                    {...a11yProps(1)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#2"
                    label={`${t('Manage')} ${t('User.Plural')}`}
                    {...a11yProps(2)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#3"
                    label={t('Account')}
                    {...a11yProps(3)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#4"
                    label={`${t('Saved')} ${t('Resource.Plural')}`}
                    {...a11yProps(4)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#5"
                    label={t('Convenient Buttons')}
                    {...a11yProps(3)}
                    sx={tabStyle}
                  />
                </Tabs>
              </Box>
              {tab === 0 && <MyResources />}
              {tab === 1 && <ManageResources />}
              {tab === 2 && <ManageUsers />}
              {tab === 3 && <Account />}
              {tab === 4 && <MySavedResources />}
              {tab === 5 && <DevButtons />}
            </Container>
          ) : user.roles.includes('100901') ? (
            <Container
              component="main"
              maxWidth="lg"
              style={{ padding: '2rem 0' }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    href="#0"
                    label={`${t('My')} ${t('Resource.Plural')}`}
                    {...a11yProps(0)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#1"
                    label={`${t('Manage Resources')}`}
                    {...a11yProps(1)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#2"
                    label={t('Account')}
                    {...a11yProps(3)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#3"
                    label={`${t('Saved')} ${t('Resource.Plural')}`}
                    {...a11yProps(4)}
                    sx={tabStyle}
                  />
                </Tabs>
              </Box>
              {tab === 0 && <MyResources />}
              {tab === 1 && <ManageResources />}
              {tab === 2 && <Account />}
            </Container>
          ) : user.roles.includes('100100') ? (
            <Container
              component="main"
              maxWidth="lg"
              style={{ padding: '2rem 0' }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    href="#0"
                    label={`${t('My')} ${t('Resource.Plural')}`}
                    {...a11yProps(0)}
                    sx={tabStyle}
                  />
                  <Tab
                    href="#1"
                    label={t('Account')}
                    {...a11yProps(3)}
                    sx={
                      tabStyle
                    } /* Could change to 1, or even change order of tabs */
                  />
                  <Tab
                    href="#2"
                    label={`${t('Saved')} ${t('Resource.Plural')}`}
                    {...a11yProps(4)}
                    sx={tabStyle}
                  />
                </Tabs>
              </Box>
              {tab === 0 && <MyResources />}
              {tab === 1 && <Account />}
            </Container>
          ) : user.roles.includes('100002') ? (
            <Box>
              <PendingApproval />
            </Box>
          ) : (
            <Box>
              <RegistrationForm />
            </Box>
          )
        }
      </ThemeProvider>
      <Footer />
    </fragment>
  );
};
export { Dashboard };
