// To disconnect test data and connect backend, follow the comments flagged with CONNECT.

import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Include in previous import?
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Menu from '@mui/material/Menu'; // Corrected import
import MenuItem from '@mui/material/MenuItem'; // There must be a faster way to do this
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
// import ExcelIcon from 'vscode-icons/dist/icons/file_type_excel.svg';  // Import the Excel icon   (Would require a dependency)

import Papa from 'papaparse';

import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from 'react-i18next';

const MyResources = () => {
  const { t } = useTranslation();
  const fileInputRef = React.useRef();

  // Sam's attempt to get the list of resources
  const [approvedResources, setApprovedResources] = useState('loading');
  const getApprovedResources = async () => {
    try {
      const response = await axiosPrivate.get('/user/approvedResources');
      setApprovedResources(response.data.payload.resources);
    } catch (error) {
      console.error(
        'Error during authentication:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  useEffect(() => {
    getApprovedResources();
  }, []);

  // Copy of attempt for pending resources
  const [pendingResources, setPendingResources] = useState('loading');
  const getPendingResources = async () => {
    try {
      const response = await axiosPrivate.get('/user/pendingResources');
      setPendingResources(response.data.payload.resources);
    } catch (error) {
      console.error(
        'Error during authentication:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  useEffect(() => {
    getPendingResources();
  }, []);

  const deleteResource = async (id) => {
    try {
      await axiosPrivate.delete('/resource/single/' + id); // Need to create a whole object to delete kind of thing?
    } catch (error) {
      console.error(
        'Error during deleting resource:',
        error.response?.data?.errMsg || error.message
      );
    }
    getApprovedResources();
    getPendingResources();
  };

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const buttonTextStyle = {
    fontFamily: 'Nunito',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 4
    textTransform: 'none',
  };

  const [importList, setImportList] = useState([]);
  const [selectedList, setSelectedList] = useState(null);

  // Control the page's dialogs
  const [googleOpen, setGoogleOpen] = React.useState(false);

  const handleGoogleOpen = () => {
    setGoogleOpen(true);
  };

  const handleGoogleClose = () => {
    setGoogleOpen(false);
  };

  const [excelOpen, setExcelOpen] = React.useState(false);

  const handleExcelOpen = () => {
    setExcelOpen(true);
  };

  const handleExcelClose = () => {
    setExcelOpen(false);
    clearFiles();
    clearLog();
  };

  const [deleteConfirmation, setDeleteConfirmationOpen] = React.useState(false);
  const [resourceToDelete, setResourceToDelete] = React.useState(null);

  const handleDeleteConfirmationOpen = (id) => {
    setResourceToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  // Dropdown menu controls
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [anchorE3, setAnchorE3] = useState(null);

  const handleListMenuClick = async (event) => {
    // Fetch data
    setAnchorE3(event.currentTarget);

    try {
      const response = await axiosPrivate.get('/user/getUserImportList');

      setImportList(response?.data?.payload?.resources);
      // const resources = response.data.payload.resources;
      // resources.forEach((resource, index) => {
      //   resource.id = index + 1;
      // });    Shouldn't be necessary--I believe each resource will eventually have its own ID.
    } catch (error) {
      console.error(
        'Error during authentication:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  const handleListMenuClose = () => {
    setAnchorE3(null);
  };

  const [menuAnchors, setMenuAnchors] = useState({});

  const handleMenuClick2 = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleMenuClose2 = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle the search, e.g., make an API request
  };

  const [importFilesArr, setImportFilesArr] = useState([]);
  const [file, setFile] = useState(false);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const uploadedFiles = droppedFiles.filter((file) =>
      file.name.toLowerCase().endsWith('.csv')
    );
    setImportFilesArr((prevFiles) => [...prevFiles, ...uploadedFiles]);
    setFile(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setImportFilesArr((prevFiles) => [...prevFiles, ...uploadedFiles]);
    setFile(true);
  };

  const handleRemoveFile = (index) => () => {
    setImportFilesArr((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
    if (importFilesArr.length === 0) {
      setFile(false);
    }
  };

  const clearFiles = () => {
    setImportFilesArr([]);
    setFile(false);
  };

  const [openStatusDialog, setopenStatusDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importCSVLog, setImportCSVLog] = useState([]);
  // importCSVLog structure
  // [{
  //   fileName: 'name',
  //   statusMessage: 'some message' or JSON obj,
  //   helpMessage: 'if needed.. may be empty'
  // }]

  const handleStatusDialogClose = () => {
    setopenStatusDialog(false);
    clearLog();
  };

  const clearLog = () => {
    setImportCSVLog([]);
  };

  // Function to handle the response from the backend
  const handleBackendResponse = async (fileName, status, help) => {
    const fileInfo = {
      fileName: fileName,
      statusMsg: '',
      log: {},
      helpMsg: help,
    };

    if (status instanceof Error) {
      fileInfo.statusMsg = status.response?.data?.errMsg || status.message;
    } else if (typeof status === 'string') {
      fileInfo.statusMsg = status;
    } else {
      if (status.status === 200) {
        fileInfo.log = status.data.payload;
      }
    }
    const existingIndex = importCSVLog.findIndex(
      (log) => log.fileName === file.name
    );
    if (existingIndex === -1) {
      setImportCSVLog((prevLog) => [...prevLog, fileInfo]);
    } else {
      const updatedLog = [...importCSVLog];
      updatedLog[existingIndex] = fileInfo;
      setImportCSVLog(updatedLog);
    }
  };

  const mapCsvToJson = (csvRow) => {
    // console.log(csvRow);
    return {
      resourceId: csvRow.resourceId || '',
      isVerified: csvRow.isVerified || '',
      importUid: csvRow.importUid || '',
      name: csvRow.name || '',
      name_es: csvRow.name_es || '',
      // Start of edits
      admin_firstName: csvRow.admin_firstName || '',
      admin_lastName: csvRow.admin_lastName || '',
      admin_email: csvRow.admin_email || '',

      admin_phone_countryCode: csvRow.admin_phone_countryCode || '',
      admin_phone_number: csvRow.admin_phone_number || '',

      website: csvRow.website || '',
      email: csvRow.email || '',

      phone_countryCode: csvRow.phone_countryCode || '',
      phone_number: csvRow.phone_number || '',

      phone_extension: csvRow.phone_extension || '',

      description: csvRow.description || '',
      description_es: csvRow.description_es || '',

      address_street: csvRow.address_street || '',
      address_city: csvRow.address_city || '',
      address_state: csvRow.address_state || '',
      address_country: csvRow.address_country || '',
      address_zip: csvRow.address_zip || '',

      latitude: csvRow.latitude || '',

      longitude: csvRow.longitude || '',
      hours_string: csvRow.hours_string || '',
      hours_object: csvRow.hours_object || '',
      serviceLanguages: csvRow.serviceLanguages || '',

      tags: csvRow.tags || '',

      // tags_es: csvRow.tags_es || '',
      services: csvRow.services || '',

      nextSteps: csvRow.nextSteps || '',
    };
  };

  // instead of erasing the object completely, i will pass to backend and the backend will merge the fields to make up the string equivalent eg: address object gets saved to address_string if any fields are incorrectly formatted or missing

  // function isEmpty(obj) {
  //   for (let key in obj) {
  //     if (obj.hasOwnProperty(key) && obj[key] === '') {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // const checkCSVObjects = (jsonData, results) => {
  //   jsonData.forEach((resource) => {
  //     if (isEmpty(resource.adminPhone)){
  //       delete resource.adminPhone;
  //     }
  //     if (isEmpty(resource.phone)) {
  //       delete resource.phone;
  //     }
  //     if (isEmpty(resource.address)) {
  //       delete resource.address;
  //     }
  //   });
  // }

  const parseFiles = async () => {
    if (!file) {
      handleBackendResponse('', 'No file selected for import', '');
      return;
    }

    // Helper function to read file as text
    const readFileAsText = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsText(file);
      });
    };

    // Promisify the Papa.parse function with preprocessed CSV content
    const parseFileAsync = async (file) => {
      try {
        const fileContent = await readFileAsText(file);
        const lines = fileContent.split(/\r\n|\n/);

        // Remove the first two lines to skip comments or instructions
        const cleanedContent = lines.slice(2).join('\n');

        // Parse the cleaned content
        return new Promise((resolve, reject) => {
          Papa.parse(cleanedContent, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              resolve(results);
            },
            error: (error) => {
              reject(error);
            },
          });
        });
      } catch (error) {
        throw new Error(`Error reading file: ${error.message}`);
      }
    };

    try {
      for (const file of importFilesArr) {
        const results = await parseFileAsync(file);
        const expectedHeaders = [
          'isVerified',
          'name',
          'name_es',
          'admin_firstName',
          'admin_lastName',
          'admin_email',
          'admin_phone_countryCode',
          'admin_phone_number',
          'website',
          'email',
          'phone_countryCode',
          'phone_number',
          'phone_extension',
          'description',
          'description_es',
          'address_street',
          'address_city',
          'address_state',
          'address_country',
          'address_zip',
          'latitude',
          'longitude',
          'hours_string',
          'hours_object',
          'serviceLanguages',
          'tags',
          'services',
          'nextSteps',
        ];

        // Extract headers from the parsed results
        let headers = results.meta.fields || [];

        // Compare extracted headers with expected ones
        headers = headers.slice(0, expectedHeaders.length);

        if (!arraysEqual(headers, expectedHeaders)) {
          // console.log(expectedHeaders);
          // console.log(headers);
          // console.error('CSV headers do not match the expected format.');
          // handleBackendResponse(
          //   file.name,
          //   'CSV does not match expected format',
          //   "Please ensure field names are correct and all lines above header begin with '#'"
          // );
          continue; // Continue to the next file
        }
        // Map CSV rows to JSON objects
        let jsonData = results.data.map(mapCsvToJson);
        // Transform jsonData to array of arrays, ensuring each array has exactly 30 elements
        let transformedData = jsonData.map((item) =>
          expectedHeaders.map((header) => item[header])
        );

        let request = {};
        request.importResourceData = JSON.stringify(transformedData);

        try {
          const result = await axiosPrivate.post(
            `resource/import/csv?importListId=${selectedList?._id}`,
            request
          );
          handleBackendResponse(file.name, result, '');
        } catch (error) {
          console.error(
            file.name,
            'Error during data import:',
            error.response?.data?.errMsg || error.message
          );
          handleBackendResponse(file.name, error, '');
        }
      }
    } catch (error) {
      console.error('Error during CSV parsing:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImport = async () => {
    setLoading(true);
    setopenStatusDialog(true);
    setExcelOpen(false);
    try {
      await parseFiles();
      clearFiles();
    } catch (error) {
      console.error(
        file.name,
        'Error during data import:',
        error.response?.data?.errMsg || error.message
      );
      handleBackendResponse(file.name, error, '');
    }
  };

  return (
    <>
      <Box sx={{ py: '30px' }}>
        <Container
          component="main"
          maxWidth="lg"
          style={{
            border: '1px solid #CACCD3',
            borderRadius: '10px',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              mb: '2rem',
              marginTop: '2rem',
            }}
          >
            <Box>
              {' '}
              {/*What does this one even do?*/}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between', // This spreads out the child elements
                  alignItems: 'center', // This vertically centers the items in the container
                  width: '100%', // Ensures the container takes full width
                }}
              >
                <div
                  style={{
                    marginLeft: '10px',
                    'font-family': 'Nunito',
                    'font-size': '28px',
                    'font-style': 'normal',
                    'font-weight': '700',
                    'line-height': '44px',
                  }}
                >
                  {t('My')} {t('Resource.Plural')}
                </div>
                <div>
                  <Button
                    onClick={() => {
                      window.open('/addResource', '_blank');
                    }}
                    variant="outlined"
                    size="large"
                    startIcon={<AddIcon />}
                    sx={{
                      ...buttonTextStyle,
                      marginRight: '10px',
                      'border-radius': '8px',
                      'border-color': '#0052CC',
                      color: '#0052CC',
                    }}
                  >
                    {t('Add')} {t('New')} {t('Resource.Singular')}
                  </Button>
                  <Button
                    onClick={handleMenuClick}
                    variant="contained"
                    size="large"
                    startIcon={<ArrowDropDownIcon />}
                    sx={{
                      ...buttonTextStyle,
                      marginRight: '10px',
                      'border-radius': '8px',
                      background: '#0052CC',
                    }}
                  >
                    {t('Import')} {t('From')}
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleExcelOpen}>
                      <ListItemIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.5858 3.3038H13.9882V1.51355L1.5 3.44105V20.3363L13.9882 22.4873V19.8338H21.5858C21.8158 19.8455 22.0412 19.7655 22.2125 19.6115C22.3839 19.4575 22.4872 19.2418 22.5 19.0118V4.12505C22.487 3.89515 22.3836 3.67972 22.2123 3.52585C22.041 3.37197 21.8157 3.29214 21.5858 3.3038ZM21.7057 19.1483H13.9628L13.95 17.7316H15.8153V16.0816H13.9357L13.9268 15.1066H15.8153V13.4566H13.9125L13.9035 12.4816H15.8153V10.8316H13.8975V9.85655H15.8153V8.20655H13.8975V7.23155H15.8153V5.58155H13.8975V4.08155H21.7057V19.1483Z"
                            fill="#20744A"
                          />
                          <path
                            d="M16.8652 5.57922H20.1075V7.22922H16.8652V5.57922ZM16.8652 8.20497H20.1075V9.85497H16.8652V8.20497ZM16.8652 10.8307H20.1075V12.4807H16.8652V10.8307ZM16.8652 13.4565H20.1075V15.1065H16.8652V13.4565ZM16.8652 16.0822H20.1075V17.7322H16.8652V16.0822Z"
                            fill="#20744A"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.76025 8.00469L6.36975 7.91244L7.3815 10.6942L8.577 7.79769L10.1865 7.70544L8.232 11.6549L10.1865 15.6142L8.48475 15.4994L7.33575 12.4814L6.186 15.3847L4.62225 15.2467L6.43875 11.7494L4.76025 8.00469Z"
                            fill="white"
                          />
                        </svg>
                      </ListItemIcon>
                      <Typography sx={buttonTextStyle}>Excel .csv</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleGoogleOpen}>
                      <ListItemIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M19 11V9H11V5H9V9H5V11H9V19H11V11H19ZM19 3C19.5 3 20 3.2 20.39 3.61C20.8 4 21 4.5 21 5V19C21 19.5 20.8 20 20.39 20.39C20 20.8 19.5 21 19 21H5C4.5 21 4 20.8 3.61 20.39C3.2 20 3 19.5 3 19V5C3 4.5 3.2 4 3.61 3.61C4 3.2 4.5 3 5 3H19Z"
                            fill="#0F9D58"
                          />
                        </svg>
                      </ListItemIcon>
                      <Typography sx={buttonTextStyle}>
                        Google Sheets
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <br />
              <Container>
                <form onSubmit={handleSearchSubmit}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />   How to get this inside the search? */}
                    <TextField
                      // label="Search"
                      variant="outlined"
                      fullWidth
                      value={searchText}
                      onChange={handleSearchChange}
                      placeholder={`${t('Search for a listing here...')}`}
                      // style={{ textAlign: 'right' }}

                      InputProps={{
                        style: {
                          // inputComponent: <Input style={{ textAlign: 'right' }} />,
                          borderRadius: '3rem',
                          fontSize: '1.5rem',
                          height: '4.5rem',
                          // marginLeft: '',
                          // paddingLeft: "15px",
                          // textAlign: "right"
                        },
                        startAdornment: (
                          <Box
                            style={{ marginRight: '10px', marginTop: '5px' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_4633_3614)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.5 2C9.1446 2.00012 7.80887 2.32436 6.60427 2.94569C5.39966 3.56702 4.3611 4.46742 3.57525 5.57175C2.78939 6.67609 2.27902 7.95235 2.08672 9.29404C1.89442 10.6357 2.02576 12.004 2.46979 13.2846C2.91382 14.5652 3.65766 15.7211 4.63925 16.6557C5.62084 17.5904 6.81171 18.2768 8.11252 18.6576C9.41333 19.0384 10.7864 19.1026 12.117 18.8449C13.4477 18.5872 14.6975 18.015 15.762 17.176L19.414 20.828C19.6026 21.0102 19.8552 21.111 20.1174 21.1087C20.3796 21.1064 20.6304 21.0012 20.8158 20.8158C21.0012 20.6304 21.1064 20.3796 21.1087 20.1174C21.111 19.8552 21.0102 19.6026 20.828 19.414L17.176 15.762C18.164 14.5086 18.7792 13.0024 18.9511 11.4157C19.123 9.82905 18.8448 8.22602 18.1482 6.79009C17.4517 5.35417 16.3649 4.14336 15.0123 3.29623C13.6597 2.44911 12.096 1.99989 10.5 2ZM4.00001 10.5C4.00001 8.77609 4.68483 7.12279 5.90382 5.90381C7.1228 4.68482 8.7761 4 10.5 4C12.2239 4 13.8772 4.68482 15.0962 5.90381C16.3152 7.12279 17 8.77609 17 10.5C17 12.2239 16.3152 13.8772 15.0962 15.0962C13.8772 16.3152 12.2239 17 10.5 17C8.7761 17 7.1228 16.3152 5.90382 15.0962C4.68483 13.8772 4.00001 12.2239 4.00001 10.5Z"
                                  fill="#666E87"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4633_3614">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                </form>
              </Container>
              {/* Would a virtualized table be better? */}
              <div style={{ height: '20px' }} />
              {/* First table: Approved resources only */}
              {approvedResources === 'loading' ? (
                <></>
              ) : approvedResources.length > 0 ? (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="420px">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Resource.Singular').toUpperCase()}{' '}
                          {t('Name').toUpperCase()} <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Status').toUpperCase()}
                          <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvedResources.map(
                      (
                        row // CONNECT: Change testResources to approvedResources to connect to backend
                      ) =>
                        row.status === 'approved' && (
                          <TableRow
                            key={row.name}
                            sx={{
                              ...buttonTextStyle,
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <div
                                style={{
                                  ...buttonTextStyle,
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                {row.name}
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <div
                                style={{
                                  width: '120px',
                                  backgroundColor: '#DCFCDB',
                                  padding: '6px',
                                  marginLeft: 'auto',
                                  borderRadius: '4px',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    style={{ paddingTop: '2px' }}
                                  >
                                    <path
                                      d="M7.15899 11.7076C6.99755 11.8691 6.73581 11.8691 6.57437 11.7076L3.54168 8.67494C3.27935 8.41261 3.27935 7.98728 3.54168 7.72494C3.80402 7.46261 4.22935 7.46261 4.49168 7.72494L6.86668 10.0999L12.5083 4.45828C12.7707 4.19594 13.196 4.19594 13.4583 4.45828C13.7207 4.72061 13.7207 5.14594 13.4583 5.40828L7.15899 11.7076Z"
                                      fill="#39A837"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#39A837' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Approved')}
                                  </Typography>
                                </Stack>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={(event) =>
                                  handleMenuClick2(event, row.resourceId)
                                }
                              >
                                <MoreVertIcon style={{ fontSize: '18px' }} />
                              </IconButton>
                              {/* How to get rid of this earlier? Probably need a big logic update (or logic understanding update lol) */}
                              <Menu
                                anchorEl={menuAnchors[row?.resourceId]}
                                open={Boolean(menuAnchors[row?.resourceId])}
                                onClose={() => handleMenuClose2(row.resourceId)}
                              >
                                <MenuItem
                                  onClick={() =>
                                    window.open(
                                      `/editResource?id=${row.resourceId}`,
                                      '_blank'
                                    )
                                  }
                                >
                                  <ListItemIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M6.414 15.89L16.556 5.74798L15.142 4.33398L5 14.476V15.89H6.414ZM7.243 17.89H3V13.647L14.435 2.21198C14.6225 2.0245 14.8768 1.91919 15.142 1.91919C15.4072 1.91919 15.6615 2.0245 15.849 2.21198L18.678 5.04098C18.8655 5.2285 18.9708 5.48281 18.9708 5.74798C18.9708 6.01314 18.8655 6.26745 18.678 6.45498L7.243 17.89ZM3 19.89H21V21.89H3V19.89Z"
                                        fill="#0052CC"
                                      />
                                    </svg>
                                  </ListItemIcon>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color: '#0052CC',
                                    }}
                                  >
                                    {t('Edit')} {t('Resource.Singular')}
                                  </Typography>
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleDeleteConfirmationOpen(row.resourceId)
                                  }
                                >
                                  <ListItemIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M14 11V17M10 11V17M6 7V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7M4 7H20M7 7L9 3H15L17 7"
                                        stroke="#EE1313"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </ListItemIcon>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color: '#EE1313',
                                    }}
                                  >
                                    {t('Delete')}
                                  </Typography>
                                </MenuItem>
                              </Menu>
                              {/* </Popover> */}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    ...buttonTextStyle,
                    color: '#000000',
                    textAlign: 'center',
                    fontSize: '1.8rem',
                  }}
                >
                  {t('You have no approved resources.')}
                </Typography>
              )}
              {/* Second table: not approved resources */}
              <div style={{ height: '40px' }} />
              {pendingResources === 'loading' ? (
                <></>
              ) : pendingResources.length > 0 ? (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="420px">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Resource.Singular').toUpperCase()}{' '}
                          {t('Name').toUpperCase()}
                          <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Status').toUpperCase()}
                          <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingResources.map(
                      (
                        row // CONNECT: Change testResources to pendingResources to connect to backend
                      ) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            ...buttonTextStyle,
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                ...buttonTextStyle,
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              {row.name}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {row.status === 'pending' ? (
                              <div
                                style={{
                                  width: '120px',
                                  backgroundColor: '#E2EAFD',
                                  padding: '6px',
                                  marginLeft: 'auto',
                                  borderRadius: '4px',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    style={{ paddingTop: '2px' }}
                                  >
                                    <path
                                      d="M8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8.00004C1.33334 4.31804 4.31801 1.33337 8.00001 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00001 14.6667ZM8.00001 13.3334C9.4145 13.3334 10.7711 12.7715 11.7712 11.7713C12.7714 10.7711 13.3333 9.41453 13.3333 8.00004C13.3333 6.58555 12.7714 5.229 11.7712 4.2288C10.7711 3.22861 9.4145 2.66671 8.00001 2.66671C6.58552 2.66671 5.22897 3.22861 4.22877 4.2288C3.22858 5.229 2.66668 6.58555 2.66668 8.00004C2.66668 9.41453 3.22858 10.7711 4.22877 11.7713C5.22897 12.7715 6.58552 13.3334 8.00001 13.3334ZM8.66668 8.00004H10.6667C11.0349 8.00004 11.3333 8.29852 11.3333 8.66671C11.3333 9.0349 11.0349 9.33337 10.6667 9.33337H7.92594C7.59866 9.33337 7.33334 9.06806 7.33334 8.74078V7.00004V5.33337C7.33334 4.96518 7.63182 4.66671 8.00001 4.66671C8.3682 4.66671 8.66668 4.96518 8.66668 5.33337V8.00004Z"
                                      fill="#1A53D0"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#1A53D0' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Pending')}
                                  </Typography>
                                </Stack>
                              </div>
                            ) : row.status === 'declined' ? (
                              <div
                                style={{
                                  width: '120px',
                                  backgroundColor: '#FDE2ED',
                                  padding: '6px',
                                  marginLeft: 'auto',
                                  borderRadius: '4px',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    style={{ paddingTop: '2px' }}
                                  >
                                    <path
                                      d="M4.73334 12.2C4.47561 12.4578 4.05774 12.4578 3.80001 12.2C3.54228 11.9423 3.54228 11.5244 3.80001 11.2667L7.06668 8.00004L3.80001 4.73337C3.54228 4.47564 3.54228 4.05777 3.80001 3.80004C4.05774 3.54231 4.47561 3.54231 4.73334 3.80004L8.00001 7.06671L11.2667 3.80004C11.5244 3.54231 11.9423 3.54231 12.2 3.80004C12.4577 4.05777 12.4577 4.47564 12.2 4.73337L8.93334 8.00004L12.2 11.2667C12.4577 11.5244 12.4577 11.9423 12.2 12.2C11.9423 12.4578 11.5244 12.4578 11.2667 12.2L8.00001 8.93337L4.73334 12.2Z"
                                      fill="#C7504F"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#A43837' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Declined')}
                                  </Typography>
                                </Stack>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '120px',
                                  backgroundColor: '#DDDDDD',
                                  padding: '6px',
                                  marginLeft: 'auto',
                                  borderRadius: '4px',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  <svg
                                    fill="#555555"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 32 32"
                                    id="icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginTop: '2px' }}
                                  >
                                    <polygon points="30 21.41 28.59 20 25 23.59 21.41 20 20 21.41 23.59 25 20 28.59 21.41 30 25 26.41 28.59 30 30 28.59 26.41 25 30 21.41" />
                                    <path
                                      d="M14,26A12,12,0,0,1,14,2Z"
                                      transform="translate(0 0)"
                                    />
                                    <path
                                      d="M17.8257,4.7642a10.0288,10.0288,0,0,1,3.2422,2.1679l1.4133-1.4135a12.0359,12.0359,0,0,0-3.89-2.6016Z"
                                      transform="translate(0 0)"
                                    />
                                    <path
                                      d="M26,14a11.93,11.93,0,0,0-.9167-4.5908l-1.8472.7651A9.9428,9.9428,0,0,1,24,14Z"
                                      transform="translate(0 0)"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#555555' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Incomplete')}
                                  </Typography>
                                </Stack>
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={(event) =>
                                handleMenuClick2(event, row.resourceId)
                              }
                            >
                              <MoreVertIcon style={{ fontSize: '18px' }} />
                            </IconButton>
                            {/* How to get rid of this earlier? Probably need a big logic update (or logic understanding update lol) */}
                            <Menu
                              anchorEl={menuAnchors[row?.resourceId]}
                              open={Boolean(menuAnchors[row?.resourceId])}
                              onClose={() => handleMenuClose2(row.resourceId)}
                            >
                              <MenuItem
                                onClick={() =>
                                  window.open(
                                    `/editResource?id=${row.resourceId}`,
                                    '_blank'
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.414 15.89L16.556 5.74798L15.142 4.33398L5 14.476V15.89H6.414ZM7.243 17.89H3V13.647L14.435 2.21198C14.6225 2.0245 14.8768 1.91919 15.142 1.91919C15.4072 1.91919 15.6615 2.0245 15.849 2.21198L18.678 5.04098C18.8655 5.2285 18.9708 5.48281 18.9708 5.74798C18.9708 6.01314 18.8655 6.26745 18.678 6.45498L7.243 17.89ZM3 19.89H21V21.89H3V19.89Z"
                                      fill="#0052CC"
                                    />
                                  </svg>
                                </ListItemIcon>
                                <Typography
                                  sx={{ ...buttonTextStyle, color: '#0052CC' }}
                                >
                                  {t('Edit')} {t('Resource.Singular')}
                                </Typography>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleDeleteConfirmationOpen(row.resourceId)
                                }
                              >
                                <ListItemIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M14 11V17M10 11V17M6 7V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7M4 7H20M7 7L9 3H15L17 7"
                                      stroke="#EE1313"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </ListItemIcon>
                                <Typography
                                  sx={{ ...buttonTextStyle, color: '#EE1313' }}
                                >
                                  {t('Delete')}
                                </Typography>
                              </MenuItem>
                            </Menu>
                            {/* </Popover> */}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  sx={{
                    ...buttonTextStyle,
                    color: '#000000',
                    textAlign: 'center',
                    fontSize: '1.8rem',
                  }}
                >
                  {t('You have no pending or declined resources.')}
                </Typography>
              )}
              <br />
              <br />
            </Box>
          </Box>
        </Container>
      </Box>

      <Dialog open={openStatusDialog}>
        <DialogContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginLeft: '5px',
              'font-family': 'Nunito',
              'font-size': '28px',
              'font-style': 'normal',
              'font-weight': '700',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('Import')} {t('Status')}
            <div style={{ margin: 'auto', padding: '10px' }}>
              <IconButton
                onClick={handleStatusDialogClose}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon style={{ fontSize: '38px', color: 'black' }} />
              </IconButton>
            </div>
          </div>
          {loading && (
            <Box sx={{ width: '85%', marginRight: '15px' }}>
              <LinearProgress
                sx={{ marginTop: '30px', marginBottom: '20px' }}
              />
              <br />
              <div
                style={{
                  textAlign: 'left',
                  translate: '5px',
                  minWidth: '400px',
                  marginBottom: '10px',
                }}
              >
                {t('Import in progress...')}
              </div>
              <div
                style={{
                  textAlign: 'left',
                  translate: '5px',
                  minWidth: '400px',
                  marginBottom: '10px',
                }}
              >
                {t('Please keep this window open untill import completes')}
              </div>
            </Box>
          )}
          <Typography
            sx={{
              ...buttonTextStyle,
              textAlign: 'center',
              fontWeight: '350',
              fontSize: '18px',
              color: 'black',
              overflowY: 'auto',
            }}
          >
            {importCSVLog.map((resData, resIndex) => (
              <p key={resIndex}>
                {resData.fileName !== '' && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>
                      {resData.fileName}
                    </span>{' '}
                    :{' '}
                  </>
                )}
                {Object.keys(resData.log).length > 0 ? (
                  <>
                    {t('Successfully added:')} {resData.log.success}
                    <br />
                    {t('Failed to add:')} {resData.log.failed}
                    <br />
                    {resData.log.log.map((element, index) => (
                      <div
                        key={index}
                        style={{
                          color: element.includes('[failed]')
                            ? 'darkred'
                            : element.includes('[success]')
                              ? 'darkgreen'
                              : 'black',
                        }}
                      >
                        {element} <br />
                      </div>
                    ))}
                  </>
                ) : (
                  resData.statusMsg
                )}

                <br />
                {resData.helpMsg !== '' && (
                  <>
                    <br />
                    &nbsp;{resData.helpMsg}
                  </>
                )}
              </p>
            ))}
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Actual code for the various dialogs */}
      <Dialog open={excelOpen} onClose={handleExcelClose}>
        <input
          type="file"
          multiple
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <DialogTitle>
          <div
            style={{
              marginLeft: '5px',
              'font-family': 'Nunito',
              'font-size': '28px',
              'font-style': 'normal',
              'font-weight': '700',
              display: 'flex',
            }}
          >
            {t('Import')} {t('With').toLowerCase()} Excel (.csv)
            <div style={{ margin: 'auto', padding: '10px' }}>
              <IconButton
                onClick={handleExcelClose}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon style={{ fontSize: '38px', color: 'black' }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '600',
            fontSize: '24px',
            color: 'black',
          }}
        >
          1.
        </Typography>

        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          Copy{' '}
          <Link
            href="https://docs.google.com/spreadsheets/d/1q-8184vp8crhkpg1mR6_j86Dkn89VwgQlQYrjvDobRQ/edit?usp=sharing"
            target="_blank"
            underline="always"
          >
            {'this CSV template'}
          </Link>{' '}
          and fill it with your data. <br /> <br />
        </Typography>

        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '600',
            fontSize: '24px',
            color: 'black',
          }}
        >
          2.
        </Typography>

        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          Select your resource import list.
        </Typography>

        <Button
          onClick={handleListMenuClick}
          variant="outlined"
          size="large"
          startIcon={<ArrowDropDownIcon />}
          sx={{
            ...buttonTextStyle,
            alignSelf: 'center',
            margin: '15px',
            marginBottom: '0px',
            'border-radius': '8px',
            'border-color': '#0052CC',
            color: '#0052CC',
            width: '200px',
            borderWidth: '2px',
            '&:hover': { borderWidth: '2px' },
            '&:active': { borderWidth: '2px' },
          }}
        >
          {selectedList ? selectedList.name : 'Select import list'}
        </Button>

        <Menu
          anchorEl={anchorE3}
          open={Boolean(anchorE3)}
          onClose={handleListMenuClose}
        >
          {importList.map((list) => (
            <MenuItem key={list._id} onClick={() => setSelectedList(list)}>
              <Typography sx={{ ...buttonTextStyle, minWidth: '120px' }}>
                {list.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '600',
            fontSize: '24px',
            color: 'black',
          }}
        >
          3.
        </Typography>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          Upload your CSV.
        </Typography>

        <Box padding="30px">
          <Container
            component="main"
            maxWidth="lg"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
              border: '4px dashed #666E87',
              borderRadius: '7px',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '10px',
              paddingLeft: '30px',
              ':hover': {
                borderColor: '#0052CC',
              },
            }}
          >
            <br />
            <br />
            {file ? (
              <div
                style={{
                  display: 'flex',
                  minHeight: '100px',
                  width: '520px',
                  margin: '5px',
                  paddingLeft: '5px',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <ul style={{ listStyle: 'none', padding: 0, width: '100%' }}>
                  {importFilesArr.map((file, index) => (
                    <>
                      <li
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <span style={{ textAlign: 'left' }}>{file.name}</span>
                        <IconButton
                          onClick={handleRemoveFile(index)}
                          style={{
                            position: 'relative',
                            top: 0,
                            right: 0,
                            size: 'small',
                          }}
                        >
                          <CloseIcon
                            style={{ color: 'black', fontSize: 'small' }}
                          />
                        </IconButton>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                >
                  <path
                    d="M11 59C9.14348 59 7.36301 58.2625 6.05025 56.9497C4.7375 55.637 4 53.8565 4 52V42C4 41.2044 4.31607 40.4413 4.87868 39.8787C5.44129 39.3161 6.20435 39 7 39C7.79565 39 8.55871 39.3161 9.12132 39.8787C9.68393 40.4413 10 41.2044 10 42V52C10 52.552 10.448 53 11 53H53C53.2652 53 53.5196 52.8946 53.7071 52.7071C53.8946 52.5196 54 52.2652 54 52V42C54 41.2044 54.3161 40.4413 54.8787 39.8787C55.4413 39.3161 56.2043 39 57 39C57.7957 39 58.5587 39.3161 59.1213 39.8787C59.6839 40.4413 60 41.2044 60 42V52C60 53.8565 59.2625 55.637 57.9497 56.9497C56.637 58.2625 54.8565 59 53 59H11Z"
                    fill="#00233D"
                  />
                  <path
                    d="M47.12 18.88C47.3984 19.1584 47.6192 19.4889 47.7699 19.8527C47.9206 20.2164 47.9981 20.6063 47.9981 21C47.9981 21.3937 47.9206 21.7836 47.7699 22.1473C47.6192 22.5111 47.3984 22.8416 47.12 23.12C46.8416 23.3984 46.5111 23.6192 46.1473 23.7699C45.7836 23.9206 45.3937 23.9981 45 23.9981C44.6063 23.9981 44.2164 23.9206 43.8527 23.7699C43.4889 23.6192 43.1584 23.3984 42.88 23.12L35 15.244V38C35 38.7956 34.6839 39.5587 34.1213 40.1213C33.5587 40.6839 32.7956 41 32 41C31.2043 41 30.4413 40.6839 29.8787 40.1213C29.3161 39.5587 29 38.7956 29 38V15.244L21.12 23.12C20.8416 23.3984 20.5111 23.6192 20.1473 23.7699C19.7836 23.9206 19.3937 23.9981 19 23.9981C18.6063 23.9981 18.2164 23.9206 17.8527 23.7699C17.4889 23.6192 17.1584 23.3984 16.88 23.12C16.6016 22.8416 16.3808 22.5111 16.2301 22.1473C16.0794 21.7836 16.0019 21.3937 16.0019 21C16.0019 20.6063 16.0794 20.2164 16.2301 19.8527C16.3808 19.4889 16.6016 19.1584 16.88 18.88L29.88 5.87999C30.1583 5.60134 30.4887 5.38027 30.8525 5.22944C31.2163 5.07861 31.6062 5.00098 32 5.00098C32.3938 5.00098 32.7837 5.07861 33.1475 5.22944C33.5113 5.38027 33.8417 5.60134 34.12 5.87999L47.12 18.88Z"
                    fill="#00233D"
                  />
                </svg>
                <br />
                <Typography
                  sx={{
                    ...buttonTextStyle,
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingBottom: '7px',
                    fontWeight: '800',
                    fontSize: '18px',
                  }}
                >
                  {t('Drag .csv here to upload')}
                </Typography>
                <br />

                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{
                    paddingBottom: '10px',
                  }} /* This feels like way too many things */
                >
                  {/* <hr style={{ border: '1px solid #666E87', width: '100%' }} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="124"
                    height="2"
                    viewBox="0 0 124 2"
                    fill="none"
                  >
                    <path
                      d="M124 1.00001L0 1"
                      stroke="#A0A6BA"
                      strokeWidth="2"
                    />
                  </svg>
                  <Typography
                    sx={{
                      ...buttonTextStyle,
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      fontWeight: '350',
                      fontSize: '25px',
                      color: '#A0A6BA',
                    }}
                  >
                    OR
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="124"
                    height="2"
                    viewBox="0 0 124 2"
                    fill="none"
                  >
                    <path
                      d="M124 1.00001L0 1"
                      stroke="#A0A6BA"
                      strokeWidth="2"
                    />
                  </svg>
                  {/* <hr style={{ border: '1px solid #666E87' }} /> */}
                </Stack>
              </>
            )}

            <br />
            <Button
              type="button"
              variant="outlined"
              size="large"
              onClick={() => fileInputRef.current.click()}
              sx={{
                ...buttonTextStyle,
                marginLeft: '30px',
                marginRight: '30px',
                'border-radius': '8px',
                'border-color': '#0052CC',
                color: '#0052CC',
                borderWidth: '2px',
                '&:hover': { borderWidth: '2px' },
                '&:active': { borderWidth: '2px' },
              }} /* Widens border at all times */
            >
              {t('Click to browse')}
              {/* "border-width" : "3px", */}
            </Button>
            <br />
            <br />
            <br />
          </Container>
        </Box>

        <br />
        <br />
        <Button
          type="submit"
          onClick={handleImport}
          variant="contained"
          size="large"
          sx={{
            ...buttonTextStyle,
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '15px',
            'border-radius': '8px',
            background: '#0052CC',
          }}
        >
          {t('Import')} {t('Resource.Plural')}
        </Button>
        <br />
        <br />
      </Dialog>

      <Dialog open={googleOpen} onClose={handleGoogleClose}>
        <DialogTitle>
          <div
            style={{
              marginLeft: '10px',
              'font-family': 'Nunito',
              'font-size': '28px',
              'font-style': 'normal',
              'font-weight': '700', // linked in wrong place
              display: 'flex',
            }}
          >
            {t('Import')} {t('with')} Google Sheets
            <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
              <IconButton
                onClick={handleGoogleClose}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon style={{ fontSize: '38px', color: 'black' }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          {t(
            'To import resources with Google Sheets, copy our template as a new file, populate it with your resources, and then share the link with us.'
          )}
          <br />
          <br />
          {t('Template')} {t('file')}:
          <Link
            href="https://docs.google.com/spreadsheets/d/1q-8184vp8crhkpg1mR6_j86Dkn89VwgQlQYrjvDobRQ/edit?usp=sharing"
            underline="always"
          >
            {' link'}
          </Link>
          <br />
          <br />
        </Typography>

        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '800',
            fontSize: '18px',
          }}
        >
          {t('Paste Link Here')}:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            padding: '30px',
            paddingTop: '5px',
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            value={searchText}
            onChange={handleSearchChange}
            placeholder="https://docs.google.com/doc/FCI_template"
            InputProps={{
              style: {
                // make it thicker?
                borderRadius: '1rem',
                fontSize: '1.5rem',
                height: '48px',
                fontWeight: 'normal' /* no in-between weight */,
              },
            }}
          />
        </Box>
        <br />
        <br />
        <Button
          type="submit"
          variant="contained"
          size="large"
          sx={{
            ...buttonTextStyle,
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '15px',
            'border-radius': '8px',
            background: '#0052CC',
          }}
        >
          {t('Import')} {t('Resource.Plural')}
        </Button>
        <br />
        <br />
      </Dialog>

      <Dialog open={deleteConfirmation} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>
          <div
            style={{
              marginLeft: '10px',
              'font-family': 'Nunito',
              'font-size': '28px',
              'font-style': 'normal',
              'font-weight': '700', // linked in wrong place
              display: 'flex',
            }}
          >
            {t('Are you sure?')}
            <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
              <IconButton
                onClick={handleDeleteConfirmationClose}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon style={{ fontSize: '38px', color: 'black' }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          {t(
            'This will permanently delete the selected resource and its data.'
          )}
        </Typography>

        <br />
        <br />
        <Button
          onClick={() => {
            deleteResource(resourceToDelete);
            handleDeleteConfirmationClose();
          }}
          type="submit"
          variant="contained"
          size="large"
          sx={{
            ...buttonTextStyle,
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '10px',
            'border-radius': '8px',
            background: '#0052CC',
          }} /* Needs an onClick to actually promote the user */
        >
          {t('Delete')} {t('Resource.Singular')}
        </Button>
        <br />
        <br />
      </Dialog>
    </>
  );
};

export { MyResources };
