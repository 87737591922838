import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Card } from '../../components/Card/Card'; // Reuse the Card component
import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from 'react-i18next';

export const MySavedResources = () => {
  const [savedResources, setSavedResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  function formatServices(services) {
    let validTitles = services
      .filter((service) => service?.title?.length <= 35)
      .slice(0, 5)
      .map((service) => service.title);
    return validTitles;
  }

  // Fetch saved resources on component mount
  useEffect(() => {
    const fetchSavedResources = async () => {
      try {
        const response = await axiosPrivate.get('/user/savedResources');
        // console.log(response.data.payload.resources);
        if (response.status === 200 && response.data.errCode === '0') {
          // Access the resources inside the payload
          setSavedResources(response.data.payload.resources);
        } else {
          // console.error('Unexpected response format:', response);
        }
      } catch (error) {
        console.error('Error fetching saved resources:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedResources();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Mapping over savedResources just like Search.js maps over filteredPins
  const cards = savedResources.map((resource, index) => (
    <Card
      id={resource.resourceId}
      key={index}
      index={index + 1}
      header={resource.name || 'Unknown Resource'}
      address={resource.address || ''}
      distance={resource.distance || ''} // Customize how you display the distance
      website={resource.website || ''}
      phone_string={resource.phone_string || ''}
      phone={resource.phone || {}}
      tags={Array.isArray(resource.tags) ? resource.tags : []} // Ensure tags are passed here
      services={formatServices(resource.services)}
      verifiedBy={resource.verifiedBy || 'Unverified'}
      active={false} // Control active status here if needed
      classScroll={`resourcecard_${resource.resourceId}`} // Optional class scroll
      timeLastUpdate={resource.timeLastUpdate || 'Not Updated'}
      description={
        typeof resource.description === 'string'
          ? resource.description
          : 'No description available'
      }
    />
  ));

  return (
    <Box>
      {savedResources.length > 0 ? (
        <div>
          {cards}{' '}
          {/* This renders the cards created by mapping saved resources */}
        </div>
      ) : (
        <Typography>{t('No saved resources found')}</Typography>
      )}
    </Box>
  );
};
