import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '../SearchBar/SearchBar';
import { Logo } from '../Logo/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enteredTermActions } from '../../store/enteredTerm-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { userActions } from '../../store/auth-slice';
import { coordsActions } from '../../store/coords-slice';
import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { ZoomSlider } from '../ZoomSlider/ZoomSlider';
import { Button } from '../Button/Button';

Header.propTypes = {
  landing: PropTypes.bool,
  setIsSearching: PropTypes.func,
  dashboard: PropTypes.bool,
  searchBar: PropTypes.bool,
};
export function Header({ landing, setIsSearching, dashboard, searchBar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authed = user?.roles?.includes('100001');
  const [zoomLevel, setZoomLevel] = useState(100);
  const navigate = useNavigate();

  const [showUserDropdown, setShowUserDropdown] = useState(false);

  const toggleUserDropdown = () => {
    setShowUserDropdown((prev) => !prev);
  };

  const [showAccessibilityDropdown, setShowAccessibilityDropdown] =
    useState(false);

  const toggleAccessibilityDropdown = () => {
    setShowAccessibilityDropdown((prev) => !prev);
  };

  const handleSignOut = async () => {
    try {
      axiosPrivate.post('/auth/signOut');
    } finally {
      dispatch(userActions.setUser(undefined));
    }
  };

  const handleClear = () => {
    dispatch(enteredTermActions.handleSearchInput(''));
    dispatch(enteredLocationActions.handleLocationInput(''));
    dispatch(coordsActions.logCoords(undefined));
  };
  const handleBrowseAll = () => {
    dispatch(enteredTermActions.handleSearchInput(''));
    dispatch(enteredLocationActions.handleLocationInput(''));
    dispatch(coordsActions.logCoords(undefined));
    navigate('/search?page=1&distance=any', { state: 1 });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (landing) {
    return (
      <div className="header-landing">
        {isMobile ? (
          // **MOBILE HEADER**
          <div className="header-landing__mobile">
            <div className="header-landing__head">
              <Logo />
              <div className="header-landing__btnwrapper">
                {/* <span className="header-landing__language">Español</span> */}
                {authed && (
                  <span
                    className="header-landing__signout"
                    onClick={() => navigate(`/dashboard`)}
                  >
                    {t('Dashboard')}
                  </span>
                )}
                {authed ? (
                  <span
                    className="header-search__signout"
                    onClick={handleSignOut}
                  >
                    {t('Sign Out')}
                  </span>
                ) : (
                  <span
                    className="header-search__signout"
                    onClick={() => navigate(`/auth/signin`)}
                  >
                    {t('Sign In')}
                  </span>
                )}
                <div className="user-menu">
                  <button
                    className="user-menu__button"
                    onClick={toggleUserDropdown}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '0',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_10463_37039)">
                        <ellipse
                          cx="16.5"
                          cy="13.2"
                          rx="3.7125"
                          ry="3.7125"
                          stroke="#174995"
                          strokeWidth="2"
                        />
                        <path
                          d="M8.21288 25.1089C9.11136 23.7342 10.3377 22.6045 11.7815 21.8218C13.2252 21.039 14.8411 20.6278 16.4834 20.625C18.1257 20.6223 19.7429 21.0281 21.1892 21.806C22.6356 22.5839 23.8658 23.7094 24.7688 25.0811"
                          stroke="#174995"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <circle
                          cx="16.5"
                          cy="16.5"
                          r="12.2"
                          stroke="#174995"
                          strokeWidth="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10463_37039">
                          <rect width="33" height="33" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {showUserDropdown && (
                    <div className="user-menu__dropdown">
                      <ul>
                        <li onClick={() => navigate('/dashboard#3')}>
                          Manage Account
                        </li>
                        <li onClick={() => navigate('/dashboard#1')}>
                          Manage Resources
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="accessibility-menu">
                  <button
                    className="accessibility-menu__button"
                    onClick={toggleAccessibilityDropdown}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '0',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <circle
                        cx="16"
                        cy="16"
                        r="11.8"
                        stroke="#174995"
                        strokeWidth="2"
                      />
                      <path
                        d="M4.80005 12H27.2001M4.80005 20H27.2001M16.6826 27.269C22.1735 20.9727 22.1725 10.2511 16.6798 4.64281C16.3114 4.26669 15.7078 4.28733 15.3586 4.68131C9.82815 10.9208 9.81391 21.011 15.3158 27.2703C15.6805 27.6851 16.3196 27.6853 16.6826 27.269Z"
                        stroke="#174995"
                        strokeWidth="2"
                      />
                    </svg>
                  </button>
                  {showAccessibilityDropdown && (
                    <div className="accessibility-menu__dropdown">
                      <ul>
                        <li>
                          <LanguageSelector />
                        </li>
                        <li>
                          <ZoomSlider
                            zoomLevel={zoomLevel}
                            setZoomLevel={setZoomLevel}
                          />
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="header-landing__search">
              <SearchBar
                className="header-search__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="landing"
              />
            </div>
            <div className="header-landing__container">
              <div className="header-landing__chapter">
                <div className="header-landing__welcome-mobile">
                  {t('Find local programs that provide the help you need.')}
                </div>
                <div className="header-landing__description-mobile">
                  {t(
                    'Whether it’s food, housing, health care, financial assistance or more.'
                  )}
                </div>
                <div className="header-landing__search-wrapper">
                  <SearchBar
                    className="header-landing__search"
                    setIsSearching={setIsSearching}
                    currentPage="landing"
                  />
                </div>
              </div>
              <div className="header-landing__illustration">
                <img
                  decoding="async"
                  loading="eager"
                  src="/media/landing-illustration.svg"
                  title="Decorative illustration about shelter, food, and medical resources"
                  alt="Decorative illustration about shelter, food, and medical resources"
                />
              </div>
            </div>
            <div className="header-landing__browse-wrapper">
              <p className="header-landing__browse-text">Or, simply</p>
              <div className="header-landing__browse">
                <Button
                  label={t('Browse all')}
                  type="button"
                  icon="arrow-forward"
                  variant="browse-all-button"
                  onClick={handleBrowseAll}
                />
              </div>
            </div>
            <div className="header-partner-logos">
              <img
                decoding="async"
                loading="eager"
                src="/media/Frame 447.svg"
                title="Decorative illustration about shelter, food, and medical resources"
                alt="Decorative illustration about shelter, food, and medical resources"
              />
            </div>
          </div>
        ) : (
          // **DESKTOP HEADER**
          <div className="header-landing__desktop">
            <div className="header-landing__head">
              <Logo />
              <div className="header-landing__btnwrapper">
                {/* <span className="header-landing__language">Español</span> */}
                <LanguageSelector />
                {authed && (
                  <span
                    className="header-landing__signout"
                    onClick={() => navigate(`/dashboard`)}
                  >
                    {t('Dashboard')}
                  </span>
                )}
                {authed ? (
                  <span
                    className="header-search__signout"
                    onClick={handleSignOut}
                  >
                    {t('Sign Out')}
                  </span>
                ) : (
                  <span
                    className="header-search__signout"
                    onClick={() => navigate(`/auth/signin`)}
                  >
                    {t('Sign In')}
                  </span>
                )}
              </div>
            </div>
            <div className="header-search__mobile">
              <SearchBar
                className="header-search__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="landing"
              />
            </div>
            <div className="header-landing__container">
              <div className="header-landing__chapter">
                <div className="header-landing__welcome heading-primary">
                  {t('Find local programs that provide the help you need.')}
                </div>
                <div className="header-landing__description">
                  {t(
                    'Whether it’s food, housing, health care, financial assistance or more.'
                  )}
                </div>
                <div className="header-landing__search-wrapper">
                  <SearchBar
                    className="header-landing__search"
                    setIsSearching={setIsSearching}
                    currentPage="landing"
                  />
                </div>
              </div>
              <div className="header-landing__illustration">
                <img
                  decoding="async"
                  loading="eager"
                  src="/media/landing-illustration.svg"
                  title="Decorative illustration about shelter, food, and medical resources"
                  alt="Decorative illustration about shelter, food, and medical resources"
                />
              </div>
            </div>
            <div className="header-landing__browse">
              <div className="header-landing__browse">
                <div className="header-landing__browse">
                  <Button
                    label={t('Browse all')}
                    type="button"
                    icon="arrow-forward"
                    variant="browse-all-button"
                    onClick={handleBrowseAll}
                  />
                </div>
              </div>
            </div>
            <div className="header-partner-sponsors">
              <p>
                {t('With reliable resources from our partners across Florida')}
              </p>
            </div>
            <div className="header-partner-logos">
              <img
                decoding="async"
                loading="eager"
                src="/media/Frame 447.svg"
                title="Decorative illustration about shelter, food, and medical resources"
                alt="Decorative illustration about shelter, food, and medical resources"
              />
            </div>
            <div className="header-landing__mobile">
              <SearchBar
                className="header-landing__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="search"
              />
            </div>
          </div>
        )}
      </div>
    );
  } else if (dashboard) {
    return (
      <div className="header-dashboard">
        <div className="header-dashboard__desktop">
          <Link to="/" onClick={handleClear}>
            <Logo />
          </Link>
          <div className="header-dashboard__btnwrapper">
            {/* <div className="header-search__language">Español</div> */}
            <LanguageSelector />
            {authed && (
              <span
                className="header-dashboard__signout"
                onClick={() => navigate('/')}
              >
                {t('Home')}
              </span>
            )}
            {authed && (
              <a
                className="header-dashboard__signout"
                href="/"
                onClick={handleSignOut}
              >
                {t('Sign Out')}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    //Search page header
    <div className="header-search">
      <div className="header-search__left-content">
        <div className="header-search__desktop">
          <Link to="/" onClick={handleClear}>
            <Logo />
          </Link>
          {searchBar && (
            <div className="header-search__search-wrapper">
              <SearchBar
                className="header-search__search"
                setIsSearching={setIsSearching}
                currentPage="search"
              />
            </div>
          )}
        </div>
        <div className="header-search__btnwrapper">
          {/* <div className="header-search__language">Español</div> */}

          {/* Button to open the slider pop-up */}
          <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
          <LanguageSelector />
          {authed && (
            <span
              className="header-search__signout"
              onClick={() => navigate(`/dashboard`)}
            >
              {t('Dashboard')}
            </span>
          )}
          {authed ? (
            <span className="header-search__signout" onClick={handleSignOut}>
              {t('Sign Out')}
            </span>
          ) : (
            <span
              className="header-search__signout"
              onClick={() => navigate(`/auth/signin`)}
            >
              {t('Sign In')}
            </span>
          )}
        </div>
      </div>
      <div className="header-search__mobile">
        <SearchBar
          className="header-search__search-mobile"
          setIsSearching={setIsSearching}
          currentPage="search"
        />
      </div>
    </div>
  );
}
